import { gql } from '@apollo/client';
const GET_ASSEMBLY_PROJECTS = gql`
  query getAssemblyProjects {
    getAssemblyProjects {
      id
      name
      creation_date
      is_owner
      thumb
      last_modification_date
      durationInSeconds
      shares {
        id
        invitee
      }
      owner {
        full_name
        email
        avatar
      }
      imported_media {
        id
      }
      archived
    }
  }
`;
const GET_ASSEMBLY_PROJECT = gql`
  query getAssemblyProject($projectId: ID!) {
    getAssemblyProject(projectId: $projectId) {
      id
      name
      is_owner
      height
      width
      timecode
      fps
      imported_media {
        id
        name
        url
        startTimeCode
        fps
        type
        height
        width
        durationInSeconds
        archived
        project_folder_id
        status
        job_id
        start_time_transcription
        file_upload_type
        project_id
        dictionary_id
        language_region_code
      }
      permission
      my_privilege
      creation_date
      shares {
        id
        invitee
        privilege
      }
      folders {
        id
        name
        archived
        imported_media {
          id
          name
          url
          startTimeCode
          fps
          type
          height
          width
          durationInSeconds
          archived
          project_folder_id
          project_id
          status
          job_id
          start_time_transcription
          file_upload_type
          dictionary_id
          language_region_code
        }
      }
    }
  }
`;
const GET_ASSEMBLY_PROJECT_AND_ALL_MEDIA = gql`
  query getAssemblyProject($projectId: ID!) {
    getAssemblyProject(projectId: $projectId) {
      id
      name
      is_owner
      imported_media {
        id
        name
        url
        startTimeCode
        fps
        type
        height
        width
        durationInSeconds
        localClientPathFile
        bookmarksProcessed
        archived
        # transcript {
        #   words {
        #     text
        #     startTime
        #     endTime
        #     style
        #   }
        #   speaker {
        #     id
        #     name
        #     color
        #   }
        #   annotation
        # }
        project_folder_id
        status
        job_id
        start_time_transcription
        file_upload_type
        project_id
        dictionary_id
        language_region_code
      }
    }
  }
`;
const GET_ASSEMBLY_MEDIA = gql`
  query getAssemblyMedia($mediaId: ID!) {
    getAssemblyMedia(mediaId: $mediaId) {
      id
      name
      url
      type
      startTimeCode
      localClientPathFile
      bookmarksProcessed
      archived
      status
      folder {
        id
        name
        archived
      }
      transcript {
        words {
          text
          startTime
          endTime
          style
        }
        speaker {
          id
          name
          color
        }
        annotation
      }
    }
  }
`;
const GET_ASSEMBLY_LATEST_VERSION = gql`
  query getLatestAssembly($projectId: ID!) {
    getLatestAssembly(projectId: $projectId) {
      id
      version
      segments {
        id
        fingerprint
        start
        end
        text
        imported_media {
          id
          name
          url
          type
          startTimeCode
          project_id
          status
          job_id
          start_time_transcription
          file_upload_type
          dictionary_id
          language_region_code
        }
        selection {
          anchorKey
          anchorOffset
          focusKey
          focusOffset
          isBackward
          hasFocus
          anchorIndex
          focusIndex
        }
        sort_index
        is_chapter
        annotations {
          id
          user {
            user_id
            full_name
            email
            avatar
          }
          creation_date
          last_modification_date
          text
          is_editable
        }
        reactions {
          type
          is_editable
        }
      }
    }
  }
`;
const ASSEMBLY_UPDATED_SUBSCRIPTION = gql`
  subscription assemblyUpdated($projectId: ID!) {
    assemblyUpdated(projectId: $projectId) {
      id
      version
      segments {
        id
        fingerprint
        start
        end
        text
        imported_media {
          id
          name
          url
          type
          startTimeCode
          project_id
          status
          job_id
          start_time_transcription
          file_upload_type
          dictionary_id
          language_region_code
        }
        selection {
          anchorKey
          anchorOffset
          focusKey
          focusOffset
          isBackward
          hasFocus
          anchorIndex
          focusIndex
        }
        sort_index
        is_chapter
        annotations {
          id
          user {
            user_id
            full_name
            email
            avatar
          }
          creation_date
          last_modification_date
          text
          is_editable
        }
        reactions {
          type
          is_editable
        }
      }
    }
  }
`;

const ACTIVE_UPDATE_SUBSCRIPTION = gql`
  subscription activeUpdated($projectId: ID!) {
    activeUpdated(projectId: $projectId) {
      project_id
      visitors {
        id
        user_id
        full_name
        email
        avatar
        temp_user_id
        active_fingerprint
      }
    }
  }
`;

const SAVE_ASSEMBLY = gql`
  mutation saveAssembly($projectId: ID!, $referenceVersion: Int, $segments: [InputSegment!]!) {
    saveAssembly(projectId: $projectId, referenceVersion: $referenceVersion, segments: $segments) {
      id
      version
      segments {
        id
        fingerprint
        start
        end
        text
        imported_media {
          id
          name
          url
          type
          startTimeCode
          project_id
          status
          job_id
          start_time_transcription
          file_upload_type
          dictionary_id
          language_region_code
        }
        selection {
          anchorKey
          anchorOffset
          focusKey
          focusOffset
          isBackward
          hasFocus
          anchorIndex
          focusIndex
        }
        sort_index
        is_chapter
        annotations {
          id
          user {
            user_id
            full_name
            email
            avatar
          }
          creation_date
          last_modification_date
          text
          is_editable
        }
        reactions {
          type
          is_editable
        }
      }
    }
  }
`;

const CREATE_ASSEMBLY_PROJECT = gql`
  mutation createNewAssemblyProject($name: String) {
    createNewAssemblyProject(name: $name) {
      id
    }
  }
`;

const CREATE_MEDIA_FOLDER_IN_PROJECT = gql`
  mutation createMediaFolderInProject($name: String!, $projectId: ID!) {
    createMediaFolderInProject(name: $name, projectId: $projectId) {
      id
      folders {
        id
        name
      }
    }
  }
`;

const RENAME_ASSEMBLY_PROJECT = gql`
  mutation renameAssemblyProject($projectId: ID!, $name: String!) {
    renameAssemblyProject(projectId: $projectId, name: $name) {
      id
      name
    }
  }
`;

const CHANGE_PERMISSION_ASSEMBLY_PROJECT = gql`
  mutation changeAssemblyProjectPermission($projectId: ID!, $permission: Permission!) {
    changeAssemblyProjectPermission(projectId: $projectId, permission: $permission) {
      id
      shares {
        id
        invitee
        privilege
      }
      permission
    }
  }
`;

const ADD_PERMISSION_FOR_EMAILS = gql`
  mutation addPermissionForEmails($projectId: ID!, $emails: [String!]!, $privilege: Permission!) {
    addPermissionForEmails(projectId: $projectId, emails: $emails, privilege: $privilege) {
      id
      shares {
        id
        invitee
        privilege
      }
      permission
    }
  }
`;

const AMEND_PERMISSION_FOR_EMAIL = gql`
  mutation amendPermissionForEmail($projectId: ID!, $email: String!, $privilege: Permission!) {
    amendPermissionForEmail(projectId: $projectId, email: $email, privilege: $privilege) {
      id
      shares {
        id
        invitee
        privilege
      }
      permission
    }
  }
`;

const AMEND_MEDIA = gql`
  mutation amendMedia($mediaId: ID!, $folderId: ID) {
    amendMedia(mediaId: $mediaId, folderId: $folderId) {
      id
      folders {
        id
        name
      }
    }
  }
`;

const AMEND_MEDIA_FOLDER = gql`
  mutation amendMediaFolder($folderId: ID!, $name: String!) {
    amendMediaFolder(folderId: $folderId, name: $name) {
      id
    }
  }
`;

const ADD_ANNOTATION_TO_SEGMENT = gql`
  mutation createAnnotation($segmentFingerprint: ID!, $text: String!) {
    createAnnotation(segmentFingerprint: $segmentFingerprint, text: $text) {
      id
      version
      segments {
        id
        annotations {
          id
          user {
            user_id
            full_name
            email
            avatar
          }
          creation_date
          last_modification_date
          text
          is_editable
        }
      }
    }
  }
`;

const AMEND_ASSEMBLY_PROJECT = gql`
  mutation amendAssemblyProject($projectId: ID!, $width: Int, $height: Int, $fps: String, $timecode: String) {
    amendAssemblyProject(projectId: $projectId, width: $width, height: $height, fps: $fps, timecode: $timecode) {
      id
    }
  }
`;

const AMEND_ANNOTATION_TO_SEGMENT = gql`
  mutation amendAnnotation($annotationId: ID!, $text: String!) {
    amendAnnotation(annotationId: $annotationId, text: $text) {
      id
      version
      segments {
        id
        annotations {
          id
          user {
            user_id
            full_name
            email
            avatar
          }
          creation_date
          last_modification_date
          text
          is_editable
        }
      }
    }
  }
`;

const AMEND_REACTION_TO_SEGMENT = gql`
  mutation amendReaction($segmentFingerprint: ID!, $type: ReactionType!, $remove: Boolean) {
    amendReaction(segmentFingerprint: $segmentFingerprint, type: $type, remove: $remove) {
      id
      version
      segments {
        id
        reactions {
          type
          is_editable
        }
      }
    }
  }
`;

const SET_BOOKMARKS_PROCESSED = gql`
  mutation markMediaBookmarksAsProcessed($mediaId: ID!) {
    markMediaBookmarksAsProcessed(mediaId: $mediaId) {
      id
      name
      url
      startTimeCode
      localClientPathFile
      bookmarksProcessed
      transcript {
        words {
          text
          startTime
          endTime
          style
        }
        speaker {
          id
          name
          color
        }
        annotation
      }
    }
  }
`;

const GET_EXPORT = gql`
  query getExport($projectId: ID!, $fileType: String!, $extension: String!) {
    getExport(projectId: $projectId, fileType: $fileType, extension: $extension) {
      base64Payload
      url
      fileType
      extension
    }
  }
`;

const ARCHIVE_ASSEMBLY_PROJECT = gql`
  mutation archiveAssemblyProject($projectId: ID!, $archive: Boolean!) {
    archiveAssemblyProject(projectId: $projectId, archive: $archive) {
      id
      name
      archived
    }
  }
`;

const ARCHIVE_MEDIA_ASSEMBLY_PROJECT = gql`
  mutation archiveMediaInAssemblyProject($projectId: ID!, $mediaId: ID!) {
    archiveMediaInAssemblyProject(projectId: $projectId, mediaId: $mediaId) {
      id
      imported_media {
        id
        name
        url
        startTimeCode
        fps
        type
        height
        width
        durationInSeconds
        type
        archived
        project_folder_id
        project_id
        status
        job_id
        start_time_transcription
        file_upload_type
        dictionary_id
        language_region_code
      }
    }
  }
`;

const ARCHIVE_MEDIA_FOLDER = gql`
  mutation archiveMediaFolder($folderId: ID!, $archive: Boolean) {
    archiveMediaFolder(folderId: $folderId, archive: $archive) {
      id
    }
  }
`;

const GET_ACTIVE_VISITORS = gql`
  query getActiveVisitors($projectId: ID!, $session: ID!) {
    getActiveVisitors(projectId: $projectId, session: $session) {
      id
      user_id
      full_name
      email
      avatar
      temp_user_id
      active_fingerprint
    }
  }
`;

const MARK_AS_ACTIVE_VISITOR = gql`
  mutation markAsActiveVisitor($projectId: ID!, $session: ID!, $active: Boolean, $activeFingerprint: ID) {
    markAsActiveVisitor(
      projectId: $projectId
      session: $session
      active: $active
      active_fingerprint: $activeFingerprint
    ) {
      id
      user_id
      full_name
      email
      avatar
      temp_user_id
      active_fingerprint
    }
  }
`;


const CLONE_ASSEMBLY_PROJECT = gql`
  mutation cloneAssemblyProject($projectId: ID!) {
    cloneAssemblyProject(projectId: $projectId) {
      id
    }
  }
`;

const CREATE_MEDIA = gql`
  mutation createMedia($filename: String!, $frameFilePath: String!, $projectId: ID!) {
    createMedia(filename: $filename, frameFilePath: $frameFilePath, projectId: $projectId) {
      id
      name
      url
      startTimeCode
      fps
      type
      height
      width
      durationInSeconds
      type
      archived
      project_folder_id
      status
      job_id
      start_time_transcription
      file_upload_type
      project_id
    }
  }
`;

const GET_SIGNED_URL = gql`
  mutation createSignUrlAws($filename: String!, $mimeType: String!, $mediaId: ID!) {
    createSignUrlAws(filename: $filename, mimeType: $mimeType, mediaId: $mediaId) {
      key
      url
      mediaId
    }
  }
`;

const IMPORT_FILE_TO_PROJECT = gql`
  mutation uploadImportMediaIntoProject(
    $mediaId: ID!
    $fileName: String!
    $type: TypeInputFile!
    $linkToFile: String
    $accessToken: String
    $fileSize: Int
    $durationInSeconds: Float
  ) {
    uploadImportMediaIntoProject(
      mediaId: $mediaId
      fileName: $fileName
      type: $type
      linkToFile: $linkToFile
      accessToken: $accessToken
      fileSize: $fileSize
      durationInSeconds: $durationInSeconds
    ) {
      id
      name
      url
      startTimeCode
      fps
      type
      height
      width
      durationInSeconds
      type
      archived
      project_folder_id
      status
      job_id
      start_time_transcription
      file_upload_type
      project_id
    }
  }
`;

const DELETE_FILE_UPLOAD = gql`
  mutation canceledImportMedia($mediaId: ID!) {
    canceledImportMedia(mediaId: $mediaId) {
      id
      name
      url
      startTimeCode
      fps
      type
      height
      width
      durationInSeconds
      type
      archived
      project_folder_id
      status
      job_id
      start_time_transcription
      file_upload_type
      project_id
    }
  }
`;

const PROCESS_UPLOAD_FILE = gql`
  subscription processUploadFile($mediaId: ID!) {
    processUploadFile(mediaId: $mediaId) {
      mediaId
      percentageProcessResponse
      errorMessage
    }
  }
`;

const GET_LANGUAGE_TRANSCRIBE = gql`
  query getLanguage {
    getLanguage {
      languages {
        id
        code
        label
        translation_code
        language_regions {
          code
          name
        }
      }
      last_used_transcription_language_region {
        code
        name
        language {
          id
          code
          label
        }
      }
      last_used_translation_language {
        id
        code
        label
        language_regions {
          code
          name
        }
      }
    }
  }
`;

const GET_LIST_NOTI = gql`
  query getNotifications($skip: Int!, $take: Int!) {
    getNotifications(skip: $skip, take: $take) {
      listNotification {
        id
        user_id
        action_type
        action_description
        creation_date
        is_read
        actor {
          full_name
          avatar
        }
        project_encoded_id
        project_name
      }
      totalUnseen
    }
  }
`;

const TRANSCRIBE_MEDIA = gql`
  mutation transcribeMedia($mediaId: ID!, $language: String!, $dictionaryId: ID) {
    transcribeMedia(mediaId: $mediaId, language: $language, dictionaryId: $dictionaryId) {
      id
      mediaId
      status
      statusText
    }
  }
`;

const UPDATE_NOTI = gql`
  mutation updateReadNotification($id: Int) {
    updateReadNotification(id: $id) {
      id
    }
  }
`;

const GET_STATUS_TRANSCRIBE = gql`
  query getStatusTranscribe($jobId: String) {
    getStatusTranscribe(jobId: $jobId) {
      id
      mediaId
      status
      statusText
    }
  }
`;

const DELETE_NOTI = gql`
  mutation deleteNotification($id: Int) {
    deleteNotification(id: $id) {
      id
    }
  }
`;

const RETRIVED_TRANSCRIBE = gql`
  mutation retrievedTranscribe($jobId: String!, $mediaId: ID!) {
    retrievedTranscribe(jobId: $jobId, mediaId: $mediaId) {
      id
      name
      url
      startTimeCode
      fps
      type
      height
      width
      durationInSeconds
      type
      archived
      project_folder_id
      status
      job_id
      start_time_transcription
      file_upload_type
      project_id
    }
  }
`;

const NOTIFICATION_ADDED_SUBSCRIPTION = gql`
  subscription notificationAdded {
    notificationAdded {
      id
      user_id
      action_type
      action_description
      creation_date
      is_read
      actor {
        full_name
        avatar
      }
      project_encoded_id
      project_name
    }
  }
`;

const TRANSLATE_MEDIA = gql`
  mutation translateMedia($mediaId: ID!, $translateCode: [String]!) {
    translateMedia(mediaId: $mediaId, translateCode: $translateCode) {
      id
      name
      url
      startTimeCode
      fps
      type
      height
      width
      durationInSeconds
      type
      project_folder_id
      status
      job_id
      start_time_transcription
      file_upload_type
      project_id
    }
  }
`;

const UPDATE_NOTI_OPENED_TIME = gql`
  mutation updateNotificationsOpenedTime {
    updateNotificationsOpenedTime
  }
`;

const SUBMIT_EMAIL_NOTI = gql`
  mutation amendEmailPreferences(
    $intercomId: ID!
    $subscribedHelp: Boolean!
    $subscribedUpdates: Boolean!
    $subscribedTransactional: Boolean!
  ) {
    amendEmailPreferences(
      intercomId: $intercomId
      subscribedHelp: $subscribedHelp
      subscribedUpdates: $subscribedUpdates
      subscribedTransactional: $subscribedTransactional
    ) {
      intercomId
      subscribedHelp
      subscribedUpdates
      subscribedTransactional
    }
  }
`;

const SAVE_TRANSCRIPT = gql`
  mutation saveTranscript($projectId: ID!, $mediaId: ID!, $transcriptJson: String) {
    saveTranscript(projectId: $projectId, mediaId: $mediaId, transcriptJson: $transcriptJson) {
      id
    }
  }
`;

const GET_USER_PROFILE = gql `
  query getUserProfile {
    getUserProfile {
      user_id
      full_name
      email
      avatar
      invite_code
      subscription_status
      planType {
        id
        label
        plan_enum
        full_label
      }
    }
  }
`;

const GET_PLAN = gql`
  query getPlan {
    getPlan {
      id
      credit_in_minutes
      label
      minimum_charge_in_minutes
      plan_enum
      price_per_hour
      subscription_charge
      active
      full_label
      name
      stripe_plan_id
    }
  }
`;

const CHARGE_CREDIT_MEDIA_BEFORE = gql `
  mutation chargeCreditMediaBefore($mediaId: ID!, $languages: [String], $type: MediaEnum){
    chargeCreditMediaBefore(mediaId: $mediaId, languages: $languages, type: $type){
      media{
        id
        status
        durationInSeconds
      }
      errorStatus
      errorMessage
      creditUsed
      creditCharged
      cardId
      languages
    }
  }
`;

const GENERATE_CUSTOMER_PORTAL = gql`
  query generateCustomerPortal {
    generateCustomerPortal
  }
`;

const GET_ID_SUBSCRIBE_PLAN = gql`
  mutation subScribePlan($planId: ID!) {
    subscribePlan(planId: $planId)
  }
`;

const UPDATE_PLAN = gql`
  mutation updatePlan($planId: ID!) {
    updatePlan(planId: $planId)
  }
`;

const CHARGE_EXTRA_CREDIT = gql `
  mutation chargeExtraCredit($extraType: ExtraEnum!, $creditNeedCharge: Int, $number: String, $cardHolderName: String, $cvc: String, $expMonth: Int, $expYear: Int, $useSavedCard: Boolean!){
    chargeExtraCredit(extraType: $extraType, creditNeedCharge: $creditNeedCharge, number: $number, cardHolderName: $cardHolderName, cvc: $cvc, expMonth: $expMonth, expYear: $expYear, useSavedCard: $useSavedCard)
  }
`;

const GET_SPEAKERS = gql `
  query getSpeaker($mediaId: ID!) {
    getSpeaker(mediaId: $mediaId) {
      id
      name
      color
    }
  }
`;

const CREATE_SPEAKER = gql`
  mutation createSpeaker($mediaId: ID!, $name: String!, $color: String) {
    createSpeaker(mediaId: $mediaId, name: $name, color: $color) {
      id
      name
      color
    }
  }
`;

const UPDATE_SPEAKER = gql`
  mutation updateSpeaker($speakerId: ID!, $name: String, $color: String) {
    updateSpeaker(speakerId: $speakerId, name: $name, color: $color) {
      id
      name
      color
      project_id
      project_name
      description
      gif_preview
      video_preview
      video_status
      img_preview
      share_flatform
      creation_date
      user {
        avatar
        full_name
      }
    }
  }
`;

const UPDATE_AVATAR_PROFILE = gql`
  mutation updateAvatar($avatar: String!) {
    updateAvatar(avatar: $avatar) {
      user_id
      full_name
      email
      avatar
      invite_code
      planType {
        id
        label
        plan_enum
        full_label
      }
    }
  }
`;

const GET_SIGNED_URL_AVATAR = gql`
  mutation getAvatarSignedUploadUrl($filename: String!, $mimeType: String!) {
    getAvatarSignedUploadUrl(filename: $filename, mimeType: $mimeType) {
      key
      url
    }
  }
`;

const GET_SHARE_PROJECT = gql`
  query getShareProject($id: Int!) {
    getShareProject(id: $id) {
      id
      project_id
      project_name
      description
      gif_preview
      video_preview
      video_status
      img_preview
      share_flatform
      creation_date
      user {
        full_name
        avatar
      }
    }
  }
`;

const EXPORT_TO_SOCIAL_MEDIA  = gql`
  mutation exportToSocialMedia($projectId:ID!, $share_flatform: String!) { 
    exportToSocialMedia(projectId: $projectId,  share_flatform:  $share_flatform) {
      id
      project_name
      description
    }
  }
`;

const GET_DICTIONARY = gql `
  query getDictionary($languageId: ID) {
    getDictionary(languageId: $languageId) {
      id
      name
      tags
      language {
        id
        code
        label
      }
      creation_date
    }
  }
`;

const SUB_TRANSLATE = gql `
  subscription processTranslateMultipleMedia($mediaId: ID!) {
    processTranslateMultipleMedia(mediaId: $mediaId) {
        media {
          id
          name
          url
          startTimeCode
          fps
          type
          height
          width
          durationInSeconds
          project_folder_id
          status
          file_upload_type
          project_id
        }
        progress
        mediaIdTranslate
    }
  }
`

const UPDATE_STATUS_MEDIA_FAIL = gql `
mutation updateStatusFailUpload($mediaId: ID!) {
    updateStatusFailUpload(mediaId: $mediaId) {
      id
      name
      url
      startTimeCode
      fps
      type
      height
      width
      durationInSeconds
      project_folder_id
      status
      file_upload_type
      project_id
    }
  }
`

export {
  GET_ASSEMBLY_PROJECTS,
  GET_ASSEMBLY_PROJECT,
  GET_ASSEMBLY_MEDIA,
  GET_ASSEMBLY_PROJECT_AND_ALL_MEDIA,
  GET_ASSEMBLY_LATEST_VERSION,
  ARCHIVE_ASSEMBLY_PROJECT,
  ARCHIVE_MEDIA_ASSEMBLY_PROJECT,
  ARCHIVE_MEDIA_FOLDER,
  ASSEMBLY_UPDATED_SUBSCRIPTION,
  ACTIVE_UPDATE_SUBSCRIPTION,
  SAVE_ASSEMBLY,
  CREATE_ASSEMBLY_PROJECT,
  CREATE_MEDIA_FOLDER_IN_PROJECT,
  RENAME_ASSEMBLY_PROJECT,
  CHANGE_PERMISSION_ASSEMBLY_PROJECT,
  ADD_PERMISSION_FOR_EMAILS,
  AMEND_PERMISSION_FOR_EMAIL,
  AMEND_MEDIA,
  AMEND_MEDIA_FOLDER,
  ADD_ANNOTATION_TO_SEGMENT,
  AMEND_ASSEMBLY_PROJECT,
  AMEND_REACTION_TO_SEGMENT,
  AMEND_ANNOTATION_TO_SEGMENT,
  SET_BOOKMARKS_PROCESSED,
  GET_EXPORT,
  GET_ACTIVE_VISITORS,
  MARK_AS_ACTIVE_VISITOR,
  SAVE_TRANSCRIPT,
  CLONE_ASSEMBLY_PROJECT,
  GET_USER_PROFILE,
  GET_SIGNED_URL,
  IMPORT_FILE_TO_PROJECT,
  CREATE_MEDIA,
  DELETE_FILE_UPLOAD,
  PROCESS_UPLOAD_FILE,
  GET_LANGUAGE_TRANSCRIBE,
  TRANSCRIBE_MEDIA,
  GET_STATUS_TRANSCRIBE,
  RETRIVED_TRANSCRIBE,
  TRANSLATE_MEDIA,
  GET_LIST_NOTI,
  UPDATE_NOTI,
  DELETE_NOTI,
  NOTIFICATION_ADDED_SUBSCRIPTION,
  SUBMIT_EMAIL_NOTI,
  UPDATE_NOTI_OPENED_TIME,
  GET_PLAN,
  GENERATE_CUSTOMER_PORTAL,
  GET_ID_SUBSCRIBE_PLAN,
  UPDATE_PLAN,
  CHARGE_CREDIT_MEDIA_BEFORE,
  CHARGE_EXTRA_CREDIT,
  GET_SPEAKERS,
  CREATE_SPEAKER,
  UPDATE_SPEAKER,
  GET_DICTIONARY,
  UPDATE_AVATAR_PROFILE,
  GET_SHARE_PROJECT,
  EXPORT_TO_SOCIAL_MEDIA,
  GET_SIGNED_URL_AVATAR,
  SUB_TRANSLATE,
  UPDATE_STATUS_MEDIA_FAIL,
};
